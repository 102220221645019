<template>
  <div>
    <header class="main-header">
      <div>민주노총 전국화학섬유식품산업 노동조합 NHN 지회</div>
      <h1>가입/연락창구</h1>
    </header>
    <div class="container">
      <div class="row">
        <div class="col-4 py-5 px-3">
          <h5 style="font-weight: 700; color: #919197;"> 가입하기 </h5>
        </div>
        <div class="col-8 py-5 px-3" style="border-bottom: 0.3px solid #555;">
          <h5 class="mb-4"><span style="color: #FF2222;">|</span> 온라인 조합원 모집 </h5>
          <p style="font-size: 13px; margin-bottom: 3px;">구글 폼을 통해 가입을 받고 있습니다. </p>
          <p style="font-size: 13px;">작성 후 가입이 완료되시면 SMS를 발송드립니다. </p>
          <button class="btn btn-outline-dark"
                  onclick="window.open('https://forms.gle/noE8VCDcN2kaZiKz7')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
            </svg>
            가입하기
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-4 py-5 px-3">
          <h5 style="font-weight: 700; color: #919197;"> 문의하기 </h5>
        </div>
        <div class="col-8 py-5 px-3" style="border-bottom: 0.3px solid #555;">
          <h5 class="mb-4"><span style="color: #FF2222;">|</span> 조합과의 소통 채널 </h5>
          <p style="font-size: 13px; margin-bottom: 3px; font-weight: 500;">카카오톡 채널: <a
              href="http://pf.kakao.com/_HVVxfG" target="_blank">@nhn그룹노조</a></p>
          <p style="font-size: 13px; font-weight: 500;">이메일 주소: nhns.union@gmail.com </p>
        </div>
      </div>
      <div class="row">
        <div class="col-4 py-5 px-3">
          <h5 style="font-weight: 700; color: #919197;"> 익명제보 </h5>
        </div>
        <div class="col-8 py-5 px-3" style="border-bottom: 0.3px solid #555;">
          <h5 class="mb-4"><span style="color: #FF2222;">|</span> 익명 제보 창구 </h5>
          <p style="font-size: 13px; margin-bottom: 3px; font-weight: 500;">구글 폼: <a
              href="https://forms.gle/yb7NFhefCtpqpFwd8" target="_blank">제보/신고하기</a></p>
          <p style="font-size: 13px; margin-bottom: 3px; font-weight: 500;">카카오톡 채널: <a
              href="http://pf.kakao.com/_HVVxfG" target="_blank">@nhn그룹노조</a></p>
          <p style="font-size: 13px; font-weight: 500;">이메일 주소: nhns.union@gmail.com </p>
        </div>
      </div>
      <div class="row">
        <div class="col-4 py-5 px-3">
          <h5 style="font-weight: 700; color: #919197;"> 익명 제보 </h5>
        </div>
        <div class="col-8 py-5 px-3">
          <h5 class="mb-4"><span style="color: #FF2222;">|</span> 부당한 근로조건 및 사내 분쟁 제보 </h5>
          <p style="font-size: 13px; margin-bottom: 3px;">&nbsp;구글 폼을 통해 관련 제보을 받고 있습니다. </p>
          <p style="font-size: 13px;">&nbsp;익명을 통한 제보도 가능합니다. </p>
          <button class="btn btn-outline-dark"
                  onclick="window.open('https://forms.gle/sDozsrsnQrYGvrGf6')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
            </svg>
            제보/신고하기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "connection",

}

</script>