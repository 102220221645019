<template>
  <div>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <a class="navbar-brand" style="color: #212126; font-size: 20px; font-weight: 700;">
              <router-link to="/">
                <img class="mx-2" style="width: 30px; border-radius: 5px;" src="./assets/logo.png">
              </router-link>
              <span>NHN 그룹 노동조합-</span>넥스트<span style="color: #FF2222;">.</span>휴먼
            </a>
            <div class="navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">선언문</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/connect">가입/연락창구</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/organization">조직도</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/faq">FAQ</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/blog">활동 기록</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <router-view />
    <footer
        class="text-center px-5 py-5"
        style="border-top: 1px solid rgb(233, 233, 233); font-size: 14px;"
    >
        <div class="mb-4" style="color: #919197;">
            <span>민주노총 전국화학섬유식품산업 노동조합</span>
            <span class="px-1">#</span>
            <span>NHN 지회</span>
        </div>
        <span style="color: #212126; font-weight: 700;">NHN 그룹사 노동조합</span>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted(){
  }
}

</script>

<style>

:root {
    --color-1: #212126;
    --color-2: #919197;
    --color-3: #FF2222;
}

#app {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  color: #212126;
}

.nav-item {
    font-size: 15px;
}
.router-link-active {
    font-weight: bolder;
    text-decoration-line: underline;
    text-underline-position : under;
}
.main-header {
    display: flex;
    height: 250px;
    background-color: rgb(215, 215, 215, 0.4);
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgb(233, 233, 233, 0.7);
    border-bottom: 1px solid rgb(223, 223, 223);
    position: relative;
}
.main-header > div {
    color: #919197;
    position: absolute;
    top: 2px;
    left: 12px;
    font-size: 13px;
}
.main-header > h1 {
    color: rgb(33, 33, 38, 0.8);
    font-weight: 700;
    text-decoration-line: underline;
    text-underline-position : under;
}
</style>
