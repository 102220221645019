<template>
  <div>
    <header class="main-header">
      <div>민주노총 전국화학섬유식품산업 노동조합 NHN 지회</div>
      <h1>활동 기록</h1>
    </header>
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-4">
          <h5 style="font-weight: 700; color: #919197;"> 2024 </h5>
        </div>
        <div class="col-10 offset-md-3">
          <ul class="timeline">
            <li>
              <a>2024.03.21</a>
              <h6 class="my-3">NHN 법인 1차 본교섭 진행! </h6>
              <p>사측과 합의한 교섭 준칙에 따라 첫 본교섭을 진행했습니다. 교섭은 10층 크루세이더 룸에서 15:00부터 17:30까지 약 두시간 반 가량 진행했습니다.<br>
                1차 교섭은 조합이 제시한 단체교섭 조항들에 대해 사측이 질의하는 형식으로 이뤄졌습니다.</p>
              <p><a href="https://cafe.naver.com/nhnsunion/111" target="_blank">자세히 보기</a></p>
            </li>
            <li>
              <a>2024.02.21</a>
              <h6 class="my-3">NHN 법인 본교섭 전 상견례! </h6>
              <p>NHN 법인의 본교섭 전 상견례가 열렸습니다. 상견례는 약 30분간 진행되었으며, 노측 대표로는 신환섭 위원장이 사측 대표로는 정연훈 NHN CHRO가 참석했습니다.<br>
                상견례장에서는 각 측 대표 및 지회장의 모두발언 후 사전 협의된 교섭 준칙을 확인했습니다.</p>
              <p><a href="https://cafe.naver.com/nhnsunion/84" target="_blank">자세히 보기</a></p>
            </li>
            <li>
              <a>2024.01.03</a>
              <h6 class="my-3">교섭대상 노동조합 선정 확정! </h6>
              <p>교섭창구단일화 절차에 따라 '전국화학섬유식품산업노동조합 소속 NHN 지회', 즉 저희 노동조합이 교섭대상 노동조합으로 선정되었습니다.<br>
                이에 따라 NHN, NHN Cloud, NHN Edu 3개 법인에 대한 교섭을 진행할 예정입니다.</p>
              <img style="width: 40%;" src="../assets/20240103.png">
              <p>[사진] NHN의 교섭 노동조합 확정 공지</p>
              <p><a href="https://cafe.naver.com/nhnsunion/7" target="_blank">자세히 보기</a></p>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <h5 style="font-weight: 700; color: #919197;"> 2023 </h5>
        </div>
        <div class="col-10 offset-md-3">
          <ul class="timeline">
            <li>
              <a>2024.03.21</a>
              <h6 class="my-3">NHN 법인 1차 본교섭 진행! </h6>
              <p>사측과 합의한 교섭 준칙에 따라 첫 본교섭을 진행했습니다. 교섭은 10층 크루세이더 룸에서 15:00부터 17:30까지 약 두시간 반 가량 진행했습니다.<br>
              1차 교섭은 조합이 제시한 단체교섭 조항들에 대해 사측이 질의하는 형식으로 이뤄졌습니다.</p>
              <p><a href="https://cafe.naver.com/nhnsunion/111" target="_blank">자세히 보기</a></p>
            </li>
            <li>
              <a>2024.02.21</a>
              <h6 class="my-3">NHN 법인 본교섭 전 상견례! </h6>
              <p>NHN 법인의 본교섭 전 상견례가 열렸습니다. 상견례는 약 30분간 진행되었으며, 노측 대표로는 신환섭 위원장이 사측 대표로는 정연훈 NHN CHRO가 참석했습니다.<br>
              상견례장에서는 각 측 대표 및 지회장의 모두발언 후 사전 협의된 교섭 준칙을 확인했습니다.</p>
              <p><a href="https://cafe.naver.com/nhnsunion/84" target="_blank">자세히 보기</a></p>
            </li>
            <li>
              <a>2024.01.03</a>
              <h6 class="my-3">교섭대상 노동조합 선정 확정! </h6>
              <p>교섭창구단일화 절차에 따라 '전국화학섬유식품산업노동조합 소속 NHN 지회', 즉 저희 노동조합이 교섭대상 노동조합으로 선정되었습니다.<br>
                이에 따라 NHN, NHN Cloud, NHN Edu 3개 법인에 대한 교섭을 진행할 예정입니다.</p>
              <img style="width: 40%;" src="../assets/20240103.png">
              <p>[사진] NHN의 교섭 노동조합 확정 공지</p>
              <p><a href="https://cafe.naver.com/nhnsunion/7" target="_blank">자세히 보기</a></p>
            </li>
            <li>
              <a>2023.12.26</a>
              <h6 class="my-3">교섭 요구 공문 발송! </h6>
              <p>화섬노조 측에서 NHN, NHN Cloud, NHN Edu에 교섭 요구 공문을 보냈습니다. 노동조합법 상의 교섭 창구 단일화 절차를 거친 후 교섭대표노조가 확정되면 본격적인 교섭이
                시작될 것입니다.</p>
              <p><a href="https://cafe.naver.com/nhnsunion/6" target="_blank">자세히 보기</a></p>
            </li>
            <li>
              <a>2023.12.21</a>
              <h6 class="my-3">타지역 계열사 방문! </h6>
              <p>NHN KCP, NHN Commerce, NHN Enterprise가 위치한 구로 사옥을 방문한 뒤, NHN Bugs와 티켓링크가 있는 신사 사옥으로 이동해 노조 가입을 권유했습니다.
                이번 방문은 직원들에게 노조 가입의 이점을 전달하고, 그룹사 직원들의 직장 생활에 대한 의견을 듣기 위한 것이었습니다. 이번 방문을 통해 노조의 목표 및 활동에 대한 정보가 담긴 전단지를
                배포하며, 고용 안정과 근로 조건 개선의 필요성을 강조했습니다. 이번 소통을 통해 직원들의 의견을 직접 듣고, NHN 그룹 내 노동자 연대 및 지지를 강화하는 중요한 첫 걸음을
                내디뎠습니다.</p>
              <img style="width: 40%;" src="../assets/20231221_visition.jpeg">
              <p>[사진] NHN노조 수석부지회장</p>
              <p><a href="https://cafe.naver.com/nhnsunion/5" target="_blank">자세히 보기</a></p>
            </li>
            <li>
              <a>2023.12.18</a>
              <h6 class="my-3">NHN 그룹사 노동조합 결성!</h6>
              <p>18일 월요일 오전 9시 그룹의 전계열사에 노동조합 결성 안내 메일을 보내어 노동조합 결성 사실을 공지했습니다.</p>
              <img style="width: 95%;" src="../assets/20231218_email.png">
              <p><a href="https://cafe.naver.com/nhnsunion/4" target="_blank">자세히 보기</a></p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Blog"
}

</script>

<style>
ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

ul.timeline > li > a {
  font-size: 18px;
}

ul.timeline > li > p {
  color: #919197;
}
</style>