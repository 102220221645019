<template>
    <div>
        <header class="main-header"> 
            <div>민주노총 전국화학섬유식품산업 노동조합 NHN 지회</div>
            <h1>FAQ</h1>
        </header>
        <div class="container">
            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 노동조합 가입 범위는 어떻게 되나요? </h5>
                <p> 다음 두 가지 조건에 해당하는 근로자를 제외하고 모든 근로자는 노동조합에 가입 가능합니다. </p>
                <p>1.임원</p><p>2.연봉결정권자</p>
                <p>가입 대상자는 노동조합 및 노동관계조정법 제2조 제1호의 규정에 의한, 근로자로, '사용자 또는 항상 그의 이익을 대표하여 행동하는 자'에 해당되지 않는 범위에서 당해 노동조합의 규약으로 정하게 됩니다.</p>
                <p>조합원 가입 자격은 직책과 직급에 상관없습니다. 수석급, 팀장님들도 가입이 가능하십니다.</p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 노동조합은 어떤 일을 하죠? </h5>
                <p> 노동조합의 목적은 근로계약을 상회하는 단체협약을 체결하는 것입니다. 단체협약은 회사와 노동조합 간에 체결되는 근로조건에 관한 협약으로, 노동조합법에 의하여  단체협약에 미달되는 개별 근로계약과 사내 규정은 무효가 되는만큼 강력한 효력을 가집니다. 저희 NHN 지회도 노동조합의 결성 후 빠른 시일 내에 단체교섭을 진행할 예정입니다. </p>
                <p> 단체교섭과 별개로 노동조합은 근로자들에 의해 자주적으로 결성된 단결체로서, 소속된 조합원들의 이익을 위한 다양한 정책사업을 진행합니다. 어떤 정책 사업을 진행할 것인지는 언제나 민주적인 과정을 통해 조합원 전체의 의사에 의해서 결정될 것입니다. </p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 회사에서 노조 가입 여부를 알고 불이익을 주진 않을까요? </h5>
                <p> 노동조합법에 따르면, 노조 활동을 이유로 회사가 불이익을 주거나 노조 가입과 활동을 방해하면 부당노동행위로 처벌받게 됩니다. 정부도 "노조의 결성을 가로막는 여러 사용자 측의 부당노동 행위는 강력한 의지로 단속하고 처벌할 것"이라고 밝혔습니다. 노조의 힘은 조합원의 수에 비례하기 때문에 조합원이 많을수록 사측에서는 부당행위를 가하기 더욱 어려워 집니다.  </p>
                <p>IT업계에 종사하는 노동자들이 겪는 문제가 당연시 되지 않는 사회 분위기가 퍼지고 있습니다. </p>
                <p>해외에는 구글, 액티비전블리자드 레이븐 소프트웨어, 유비소프트, 미국 프리랜서 및 계약직을 대변하는 코드 CWA(CODE CWA), 캐나다 몬트리올 게임 개발자 연합 등이 존재하며 국내에는 네이버, 넥슨, 엔씨, 스마일게이트, 카카오, 엑스엘게임즈, 안랩, 웹젠, 한국마이크로소프트, 한국오라클, SAP코리아 등이 존재하고 있습니다.</p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 회사에서 가입여부를 알 수 있나요?</h5>
                <p> 회사는 개인의 노조가입 여부를 절대로 알 수 없습니다. 조합원의 집단적 의사결정에 의한 찬성 또는 개인적 동의 없이 조합원 명단이 회사에 공유되는 일은 없습니다. </p>
                <p> 조합비를 월급에서 공제하는 체크오프(check-off) 협약 시에도, 개별적으로 명단 공개를 거부할 수 있습니다.</p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 조합원 간에는 서로의 가입여부를 알 수 있나요?</h5>
                <p> 간부가 아닌 조합원이 조합원 명단을 볼 수는 없습니다. 조합원들의 집단적 의결에 따라 실명의 커뮤니티 공간을 만들 수는 있습니다. </p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 노동조합 가입은 늦게해도 상관 없나요?</h5>
                <p> 노동조합이 힘을 얻고 정착하려면 초기에 많은 가입자의 확보가 대단히 중요합니다. 노조가 생겼는데 가입하는 사람이 별로 없으면 힘을 받지 못하게 되고, 회사는 노조의 요구를 가볍게 무시할 수 있게 됩니다. 권리를 찾는다는 마음으로 가입을 부탁드립니다.  </p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 노조가 올바른 방향으로 잘 유지되려면 어떻게 해야 하나요?</h5>
                <p> 노조는 조합원들의 의견에 따라 민주적으로 운영됩니다. 노조의 대표가 잘못된 방향으로 나간다면 탄핵도 할 수 있습니다. 무엇보다도 조합원들 한분한분이 관심과 애정을 가지고 활동해 주신다면 노조는 투명하며 강력하게 나아갈수 있을 것입니다. 노조 밖에서 관망할 것이 아니라 노조에 가입하여 목소리를 내주셔야 노조와 회사 모두 올바른 방향으로 갈 수 있습니다. </p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 조합비는 얼마인가요?</h5>
                <p> 회사와의 단체협약 전까지는 아래와 같이 월정액의 조합비를 CMS 방식으로 공제 예정입니다. 현재 단계의 조합비는 IT노조 초기단계의 조합비를 참고하였습니다. </p>
                <p>■ 조합비 : 월 30,000원</p>
                <!-- <p>* 노사 간 단체협약이 체결될 경우, 급여에서 조합비를 일괄 공제(check-off)합니다.</p>
                <p>* 일괄공제 시 조합비는 통상임금의 1%로 측정될 예정입니다. (상한액 4만원, 하한액 2만원 - 최저임금 변동에 따라 조정가능)</p> -->
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 조합비는 어떻게 쓰이고 그 내역을 어떻게 알 수 있나요?</h5>
                <p> 조합비는 조직활동비, 홍보, 교육, 조사연구, 연대활동, 노동 안전보건, 회의비 등의 항목으로 쓰입니다. 예를 들어, 회의나 조합원 간담회(부서별 등)를 하면 자료 제작비용, 참가자들 식사 비용 등을 조합비로 지출합니다.</p>
                <p>또한, 아래의 경우에도 조합비가 지출 됩니다.</p>
                <p>1. 부당한 해고나 징계 등 불이익을 당할 경우, 법률상담이 필요한 경우 소송비용 부담</p>
                <p>2. 특히 노조활동 과정에서 불이익을 당한 경우, 최소한의 생계비 지원</p>
                <p>3. 단체교섭시 회사의 인사, 복지, 임금체계 등의 개선이 필요한 정책 연구사업 지원</p>
                <p>4. 정부정책과 관련되어 관련부처 및 국회상임위 등에 노조의 요구 전달, 캠페인 등의 사업 지원</p>
                <p>지출내역은 상, 하반기 연 2회의 회계감사를 받게 됩니다. 또한, 정기적으로 개최되는 총회나 대의원대회에 회계 감사결과와 결산 내역을 보고하고 승인을 받아야 하므로 조합비는 투명하게 운영될 것입니다.</p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 휴직자의 경우 가입이 가능한가요?</h5>
                <p> 휴직자의 경우도 당연히 노조 가입은 가능합니다. 다만 조합비는 휴직기간에 공제가 중지 됩니다.</p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 기업별 노조, 산업별 노조가 무엇인가요?</h5>
                <p> 노조 조직형태에 따른 구분입니다. 같은 기업 노동자들끼리만 결성하면 기업별 노조, 동종 산업에 종사하는 노동자들끼리 결성하면 산업별 노조가 됩니다. 그런데 기업별 노조는 일본과 우리나라에만 주로 존재하고 서구 유럽에는 기업별 노조가 없습니다. 산업별로 크게 뭉쳐야 더 큰 힘이 생기기 때문 입니다. NHN 지회는 산업별 노조 형태입니다.</p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 왜 민주노총 화섬식품 노조(전국 화학 섬유 식품 산업 노조)를 선택하였나요?</h5>
                <p> 민주노총 화섬노조는 네이버를 비롯하여, 카카오, 넥슨, SK하이닉스, 엔씨, LIG넥스원, LG생활건강 등의 다양한 사무직 노조설립 및 운영 경험이 있습니다.</p>
                <p> 노동조합 경험이 없는 인원들로만 기업별 노동조합을 구성한다면 상당한 교섭 비용, 법률 자문 비용을 지출하고도 유의미한 성과를 내지 못할 수 있어 경험이 많은 상급단체와의 협력을 선택하였습니다. </p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 화섬노조(상급단체)에 의해 운영이 좌지우지될 위험이 없나요?</h5>
                <p> 지회의 조직 운영 관련 권한은 지회 조합원들이 가지고 있습니다. 화섬노조는 교섭의 당사자로서 사측과의 교섭에 함께 참여하는 조력자 및 부당노동행위구제 등 법적 절차 상의 조력자 역할을 하게 됩니다. 지회의 성공과 실패는 지회에 소속된 조합원들의 의지에 달려있습니다. </p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 그룹 내 법인별로 노동조합이 존재하게 되는 것인가요?</h5>
                <p> 그렇지 않습니다. NHN 그룹 지회는 NHN 그룹사 근로자들이 법인에 상관없이 함께 모인 하나의 노동조합이며, 사안에 따라서 법인 별 내부조직을 구성할 수는 있겠지만 화섬노조 NHN 본사 지회, 화섬노조 NHN Cloud 지회 ... 등으로 노동조합 자체가 나뉘어 존재하지는 않을 것입니다.</p>
                <p> 그룹 전체에 하나의 단일한 노동조합이 있어야 하는 것은 아니며, 법인 별로 따로 지회를 두는 것이 법적으로 불가능한 것은 아닙니다. 하지만 저희 NHN 그룹 지회는 NHN 그룹 내 모든 임직원들이 하나로 단결하는 것이 모두에게 이득이라는 판단 하에 그룹 단위의 노동조합을 결성하였습니다. </p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 단체교섭도 그룹단위로 이루어지나요?</h5>
                <p> 아쉽지만, 그렇지 않습니다. 노동조합은 그룹 전체 단위로 존재하더라도 단체교섭은 법인 별로 진행이 되어야 합니다. 즉, NHN 본사의 단체교섭과 NHN Cloud의 단체교섭과 NHN Payco의 단체교섭 등은 원칙상 별개로 진행됩니다. 법인별 단체교섭을 하나로 묶어 공동교섭을 행할 수 있지만 사측에서 수용하지 않는다면 이를 강제할 수는 없습니다.</p>
                <p> 따라서, NHN 그룹 노동조합에 가입을 하였지만 자신의 법인의 단체교섭은 진행이 안되는 경우가 있을 수 있습니다. 원칙상 조합원이 한명이라도 있는 법인이라면 노동조합이 사측에게 교섭을 요구할 수는 있지만, 집행부인원 없이 조합원들만 있는 법인의 경우 현실적으로 교섭이 어려울 수 있습니다. 즉 특정 법인의 단체교섭을 위해선 해당 법인의 조합원들을 대표할 수 있는 집행부원이 필요합니다. </p>
                <p> 물론 단체교섭을 진행하지 못한 법인의 조합원이라도 노동조합의 정책사업의 혜택을 받을 수 있습니다. </p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 조합원과 집행부의 차이는 무엇인가요?</h5>
                <p> 집행부는 노조활동을 기획하고 추진하는데 직접 참여하게 됩니다. 사우들의 의견을 듣고 종합하여 정리하는 활동, 사우들에게 배포할 이미지나 내용을 만드는 활동, 조합비를 집행하고 관리하는 활동 등 동아리 운영 스태프들의 일과 비슷하다고 봐주시면 됩니다. </p>
                <p> 특히 집행부 인원 중 각 법인을 대표하는 분들을 법인별 부지회장으로 임명하게 될 것입니다. </p>
                <p> 집행부에 참여하고 싶으시다면 언제든지 홈페이지에 명시된 연락수단으로 연락주십시오. </p>
            </div>

            <div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 종이 가입원서와 구글 Forms는 무슨차이 인가요?</h5>
                <p> 종이 가입원서로 작성하여 제출하시면 정식가입부터 CMS 이체 동의까지 일괄 처리되는 방식입니다. </p>
                <p> 종이 가입원서로 제출 희망하시는 분들은 CMS 이체동의 내용을 작성하여 NHN 노동조합 카카오톡 채널 또는 대표 이메일(nhns.union@gmail.com)로 보내주시기 바랍니다.</p>
                <p>구글 Forms로 가입하신 분들에게는 CMS 이체 처리를 위한 개별 문자 메시지를 발송해드릴 예정입니다.</p>
            </div>

		<div class="faq-box row m-3 p-3">
                <h5 class="mb-4"> Q. 집행부는 실명을 공개해야하나요?</h5>
                <p> 실명을 반드시 공개할 필요는 없습니다. 하지만 협상에 참여하는 집행부는 실명을 공개하는 것이 필요합니다. </p>
                <p> 비공개로 활동하는 집행부는 내부적으로 서포터라고 불리며, 디자인과 다양한 방면에서 도움을 제공하고 있습니다.</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Faq",
    
}

</script>

<style>
.faq-box > p {
    color: #919197;
}
</style>