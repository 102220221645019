<template>
    <div>
        <header class="main-header"> 
            <div>민주노총 전국화학섬유식품산업 노동조합 NHN 지회</div>
            <h1>조직도</h1>
        </header>
        <div class="container text-center">
            <h5 class="my-4 px-3"> <span style="color: #FF2222;">|</span> NHN 지회 </h5>
            <img style="width: 70%;" src="../assets/organization.png" />
        </div>
        <div class="container text-center">
            <h5 class="my-4 px-3"> <span style="color: #FF2222;">|</span> NHN 지회 집행부 </h5>
            <p>
                <span style="font-weight: 500;">지회장 : </span>
                <span style="color: #919197;">NHN 배대영 전임</span>
            </p>
            <p>
                <span style="font-weight: 500;">수석부지회장 : </span>
                <span style="color: #919197;">NHN Cloud 이동교 선임</span>
            </p>
            <p>
                <span style="font-weight: 500;">부지회장 : </span>
                <span style="color: #919197;">NHN Edu 김승진 선임</span>
            </p>
            <p>
                <span style="font-weight: 500;">사무장 : </span>
                <span style="color: #919197;">NHN 김성오 책임</span>
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: "Organization",
    
}

</script>