import { createWebHistory, createRouter } from "vue-router";
import Declaration from '../components/Declaration.vue';
import Connection from '../components/Connection.vue';
import Organization from '../components/Organization.vue';
import Faq from '../components/Faq.vue';
import Blog from '../components/Blog.vue';

const routes = [
    {
        path: "/",
        name: "declare",
        component: Declaration
    },
    {
        path: "/connect",
        name: "connect",
        component: Connection
    },
    {
        path: "/organization",
        name: "organization",
        component: Organization
    },
    {
        path: "/faq",
        name: "faq",
        component: Faq
    },
    {
        path: "/blog",
        name: "blog",
        component: Blog
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;