<template>
    <div>
        <header class="main-header">
            <div>민주노총 전국화학섬유식품산업 노동조합 NHN 지회</div>
            <h1>선언문</h1>
        </header>
        <div class="container">
            <div class="row">
                <div class="col-4 px-2 py-5">
                    <h5 style="font-weight: 700; color: #919197;"> 2023.12.18 </h5>
                    <h5 style="font-weight: 900; color: #FF2222;">NHN</h5>
                    <h5 style="font-weight: 700; color: #212126;"> 노동조합 결성 </h5>
                </div>
                <div class="declare-content col-8 px-2 py-5">
                    <p> 최근 NHN은 여러 이유로 복지, 근무 제도를 축소·변경하고 있습니다. 이러한 일들은 회사가 어렵다는 이유로 일방적으로 진행되고 있습니다. 우리가 겪고 있는 문제들에 대해 회사에 묻지 않을 수 없습니다. 이젠 진정으로 변화해야 합니다. 그리고 이 변화의 출발은 NHN 노동조합입니다.</p>
                    <p> 노동조합은 다음의 세 가지 원칙을 기반으로 활동할 것입니다. </p>
                    <br/><h6> 법률에 근거한 정당성 </h6>
                    <p> 노동조합 활동과 노동조건 향상을 위한 요구는 법적인 권리이며 노동조합의 결성과 활동의 정당성은 법률에 근거합니다. </p>
                    <br/><h6> 노동조건의 지속적 개선 </h6>
                    <p> 노동조합은 노동조건의 개선 및 노동자 지위의 향상이라는 노동조합의 근본적인 목적을 활동의 중심에 둘 것입니다. 이를 바탕으로 고용안정 보장, 임금 상승, 복지 강화, 노동조건 개선, 사업장 내 차별 철폐, 노사 및 노동자 사이의 화합 등 조합원, 나아가 전체 임직원의 이익을 위한 과제들을 꾸준히 해결해 나갈 것입니다. </p>
                    <br/><h6> 공동체로서의 참여 </h6>
                    <p> NHN 그룹 아래에 묶인 모든 사람은 하나의 공동체이며, 고객의 신뢰를 얻기 위해선 먼저 경영진과 노동자 상호 간의 신뢰와 공감이 필요합니다. NHN의 발전을 위해 경영진에게 공동의 목적을 지향하는 동지로서 노동자들의 참여와 소통을 요구할 것입니다.</p>
                    <br/><p>노동조합에 많은 법인이 참여할수록, 많은 노동자가 참여할수록 우리의 힘은 더 커질 것입니다. 적극적인 조합 가입과 참여를 거듭 부탁드립니다.</p>
                    <br/><p>노동조합의 설립이 NHN 그룹 임직원에게 있어 긍정적인 변화를 일으킬 수 있도록 노력하겠습니다!</p>
                    <br/><p style="color: #919197;">전국화학섬유식품산업노동조합 NHN 지회</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "declaration",
    
}
</script>
<style>
.declare-content {
    font-size: 13px;
    color: #212126;
}
.declare-content > p {
    margin-bottom: 3px;
}
.declare-content > h6 {
    text-decoration-line: underline;
    text-underline-position : under;
    text-decoration-color: #919197;
}
</style>